<script setup/>

<template>
    <div class="d-flex flex-column h-100 justify-content-center align-items-center">
      <p class="num-res normal-font text-gray">404</p>
      <p class="font-18 mid-font text-black">Страница не найдена</p>
    </div>
</template>

<style scoped>
.num-res {
  font-size: 152px;
  text-align: center;
}
</style>